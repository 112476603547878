import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import {
  DemoButton,
  GetStartedButton,
  LearnMoreButton,
  Faq,
  CompareSection
} from "../commonComponents"

import {
  IntegrationDarkSection,
  IntegrationHeroSection,
} from "../integrationsUtils"
import {
  IntegrationsMiddleSectionImageLeft,
  IntegrationsMiddleSectionImageRight,
} from "../integrationsMiddleSection"


import { Layout } from "../components/Layout"


export default function ZendeskAIComparison() {
  const SERVICE_NAME = "zendesk"
  const BACKGROUND_SRC = `/img/${SERVICE_NAME}-bg.png`
  const BACKGROUND_WEB_POSITION = "100%"
  const BACKGROUND_MOBILE_POSITION = "35% 50px"
  const ZendeskIntroWorksWith = () => (
    <div className="intro-integration-work-with">
      Works with
      <br />
      <img
        src="/img/zendesk_support_vertical.svg"
        className="img-fluid intro-integration-work-with-image mt-2 mr-2"
        alt="zendesk support"
      />
      <img
        src="/img/zendesk_guide_vertical.svg"
        className="img-fluid intro-integration-work-with-image mt-2"
        alt="zendesk guide"
      />
    </div>
  )
  return (
    <Layout
      title="Why IrisAgent is better than Zendesk AI?"
      keywords="IrisAgent vs Zendesk, IrisAgent compare with Zendesk AI"
      description="IrisAgent is a comprehensive, feature-rich AI support automation platform designed to seamlessly integrate with your CRM system. Discover below why IrisAgent offers superior support automation compared to native Zendesk Automation."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/zendesk-ai-and-irisagent/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <IntegrationHeroSection
        serviceName={SERVICE_NAME}
        titleIntegration={
          <>
            <span className="title-fucsia-color">
              IrisAgent vs <br/>Zendesk AI:
            </span> Why it's<br/> a nobrainer?
          </>
        }
        descriptionIntegration={
          <>
            <span>
              IrisAgent is a comprehensive, feature-rich AI support
              automation platform designed to seamlessly integrate
              with your CRM system. Discover below why IrisAgent
              offers superior support automation compared to native
               Zendesk Automation.
            </span>
          </>
        }
        sectionBackgroundSrc={BACKGROUND_SRC}
        sectionBackgroundWebPosition={BACKGROUND_WEB_POSITION}
        sectionBackgroundMobilePosition={BACKGROUND_MOBILE_POSITION}
        workWithRow={<ZendeskIntroWorksWith />}
      />
      {/* <!-- End Hero --> */}

      {/* <!-- ======= Counts Section ======= --> */}
      <CompareSection theme="dark" service="Zendesk"/>
      {/* <!-- End Counts Section --> */}
      <main id="main">



        <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "4%", margin:"10%" }}>
            <h2><span style={{ color: "#EF5DA8", fontWeight: 600 }}>Check out how we compare</span></h2>
            <br/>
            <table>
                <tr>
                    <th>Features</th>
                    <th>IrisAgent</th>
                    <th>Zendesk</th>
                </tr>
                <tr>
                    <td>Generative AI Chatbot</td>
                    <td>✔ LLM based<br/>
                    ✔ Configurable LLM federation with OpenAI, Anthropic, Azure, and more<br/>
                    ✔ Integrates to multiple KBs and sources<br/>
                    ✔ 95%+ accuracy, zero hallucinations<br/><br/></td>
                    <td>⤬ Rule based<br/>
                        ⤬ Restricted to Zendesk KB
                    </td>
                </tr>
                <tr>
                    <td>Email auto-responder</td>
                    <td>✔ AI-Powered</td>
                    <td>⤬ Rule based</td>
                </tr>
                <tr>
                    <td>Intelligent AI Search</td>
                    <td>✔ AI-Powered and Federated across multiple knowledge sources</td>
                    <td>⤬ Rule based and only searches Zendesk data</td>
                </tr>
                <tr>
                    <td>AI Agent Assist</td>
                    <td>✔ LLM based answers from articles, historical tickets, and bugs<br/>
                        ✔ AI-powered Macro auto-selection</td>
                    <td>⤬ Scripted, searches only Zendesk data<br/>
                        ⤬ Manual macro selection</td>
                </tr>
                <tr>
                    <td>Automated tagging, routing, and triaging</td>
                    <td>✔ AI powered, trained on historical tickets</td>
                    <td>⤬ Keyword based tagging and routing
                      </td>
                </tr>
                <tr>
                    <td>Sentiment and escalation analysis</td>
                    <td>✔ AI powered, granular, measured per ticket </td>
                    <td>⤬ Keyword and rule-based
                      </td>
                </tr>
            </table>
            <br/>
            </div>
          </div>
        </section>
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Try out{" "}
            <span className="title-fucsia-color">IrisGPT</span> on your own data for free
          </span>
        }
        button={
          <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
        }
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}
      {/* <!-- ======= Middle Section ======= --> */}
      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/zendeskMiddle1.webp"
        imageTextMiniature="/img/zendeskMiniature.webp"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={<>Resolve common tickets automatically with AI</>}
        descriptionTextContent={
          <>
            Fine-tuned LLMs on your data for lightning fast speed and accuracy. Access appropriate resolutions based on similar tickets and
            articles from within Zendesk.
          </>
        }
      />
      <IntegrationsMiddleSectionImageRight
        mainImage="/img/irisgpt.svg"
        imageTextMiniature="/img/zendeskMiniature.webp"
        backgroundSectionColor="#F9F9F9"
        descriptionTextTitle={
          <>
            Deflect 40% of tickets and chats with IrisGPT
          </>
        }
        descriptionTextContent={
          <>
            Unlike conventional rule-based chatbots like Zendesk, which rely on predefined answers and often provide a frustrating experience, IrisGPT learns your entire knowledge base in minutes. It can handle any query with a deep understanding of customer nuances, ensuring a seamless and satisfying support experience.
          </>
        }
      />
      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/zendeskMiddle3.webp"
        imageTextMiniature="/img/zendeskMiniature.webp"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={
          <>
            Automate ticket tagging,
            routing and prioritization within Zendesk
          </>
        }
        descriptionTextContent={
          <>
            IrisAgent discovers tags customized to your domain and automatically
            tags new tickets. Get rid of manual and inaccurate ticket tagging.
          </>
        }
      />







        {/* <!-- ======= Contact Services Section ======= --> */}
        {/* <ContactSection /> */}
        {/* <!-- End Contact Section --> */}
      </main>
      {/*  End #main  */}
    </Layout>
  )
}